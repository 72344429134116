import React from "react"

export const OFFICES = [
  {
    id: "USA",
    location: (
      <>
        <span itemProp="addressCountry">The USA</span>,{" "}
        <span itemProp="addressLocality">Washington</span>
      </>
    ),
    address: <span itemProp="streetAddress">Washington, DC 20036</span>,
    phone: "+1 206 681 1521",
    alias: "us",
    addressLink: "https://maps.app.goo.gl/Tbfb2K9S5vLME5o69",
    imgHeight: 217,
    testimonial: {
      authorName: "Layla Atya",
      authorImage: "layla-atya",
      authorPosition: "CEO Zala International. Principal Insurance Consultant",
      text:
        "In 2020, Zala and LaSoft joined forces to provide world-class services in Insurtech. Now, we are technology experts who speak your language, understand your pain, and are ready to offer next-level solutions that work.",
    },
  },
  {
    id: "CHE",
    location: <span itemProp="addressCountry">Switzerland</span>,
    address: <span itemProp="streetAddress">Zürich</span>,
    phone: "+41 076 201 157 1",
    alias: "che",
    addressLink: "https://maps.app.goo.gl/whFPimQakEUTsnks9",
    imgHeight: 214,
    testimonial: {
      authorName: "Sylvestre Lucia",
      authorImage: "sylvestre-lucia",
      authorPosition: (
        <>
          Product manager <br />& Customer Expirience
        </>
      ),
      text:
        "Now we have our representative in Switzerland. We are encouraging you to connect with Sylvestre Lucia, who represents LaSoft in Zurich",
    },
  },
  {
    id: "Poland",
    location: (
      <>
        <span itemProp="addressCountry">Poland</span>,{" "}
        <span itemProp="addressLocality">Łódź</span>
      </>
    ),
    address: (
      <span itemProp="streetAddress">
        8 Franklina Delano Roosevelta&nbsp;st.
      </span>
    ),
    phone: "+48 422 202 701",
    alias: "pl",
    addressLink: "https://maps.app.goo.gl/rvLPD3jSWxJuha7e6",
    imgHeight: 291,
  },
  {
    id: "Ukraine",
    location: (
      <>
        <span itemProp="addressCountry">Ukraine</span>,{" "}
        <span itemProp="addressLocality">Lviv</span>
      </>
    ),
    address: <span itemProp="streetAddress">7 Muchna st.</span>,
    phone: "+38 091 481 07 01",
    alias: "ua",
    addressLink: "https://maps.app.goo.gl/B6RdSzCCwEm2drnm9",
    imgHeight: 231,
  },
  // {
  //   id: "Estonia",
  //   location: "Estonia, Tallinn",
  //   address: "Tartu mnt. 2, 10145",
  //   phone: "+372 58297220",
  //   alias: "ee",
  // },
  // {
  //   id: "Mexico",
  //   location: "Mexico",
  //   address: "Tartu mnt. 2, 10145",
  //   phone: "+372 58297220",
  //   alias: "mx",
  // },
]
