import React from "react"
import cx from "classnames"

import * as styles from "./styles.module.scss"
import { IMG } from "../../const"

const Testimonial = ({ testimonial, desktop }) => (
  <div
    className={cx(styles.testimonial, desktop ? styles.desktop : styles.mobile)}
  >
    <figure>
      <figcaption itemScope itemType="https://schema.org/Person">
        <picture>
          <source
            srcSet={`${IMG.INDEX}/img/contacts/testimonials/${testimonial.authorImage}.avif`}
            type="image/avif"
          />
          <source
            srcSet={`${IMG.INDEX}/img/contacts/testimonials/${testimonial.authorImage}.webp`}
            type="image/webp"
          />
          <source
            srcSet={`${IMG.INDEX}/img/contacts/testimonials/${testimonial.authorImage}.jpg`}
          />
          {testimonial.authorImage && (
            <img
              src={`${IMG.INDEX}/img/contacts/testimonials/${testimonial.authorImage}.jpg`}
              alt={testimonial.authorName}
              width="54"
              height="54"
              loading="lazy"
              itemProp="image"
            />
          )}
        </picture>
        <div>
          <div className={styles.authorName} itemProp="name">
            {testimonial.authorName}
          </div>
          <span itemProp="jobTitle">{testimonial.authorPosition}</span>
        </div>
      </figcaption>
      <blockquote>
        <p>{testimonial.text}</p>
      </blockquote>
    </figure>
  </div>
)

const Office = ({ item, screenWidth }) => {
  return (
    <li
      id={item.id}
      className={
        item.testimonial
          ? styles.officeCardWrapper__wide
          : styles.officeCardWrapper
      }
    >
      <article itemScope itemType="http://schema.org/LocalBusiness">
        <div>
          <div className={styles.officeCardMap} id={`card-${item.id}`}>
            <img
              alt={`map of ${item.id}`}
              src={`${IMG.INDEX}/img/contacts/${item.alias}.svg`}
              loading="lazy"
              width="343"
              height={item.imgHeight}
            />

            {/* Map active */}
            <img
              alt={`map of ${item.id}`}
              src={`${IMG.INDEX}/img/contacts/${item.alias}-active.svg`}
              className={styles.activeMap}
              loading="lazy"
              width="343"
              height={item.imgHeight}
            />
          </div>

          {item.testimonial && (
            <Testimonial testimonial={item.testimonial} desktop={false} />
          )}

          <div
            className={styles.officeCard}
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress"
          >
            <div className={styles.officeFlag}>
              <img
                src={`${IMG.INDEX}/img/contacts/flg-${item.alias}.svg`}
                alt={`${item.id} flag`}
                width="56"
                height="40"
                loading="lazy"
              />
            </div>
            <dl>
              <a
                className={styles.addressLink}
                href={item.addressLink}
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                <dd className={styles.location}>{item.location},</dd>
                <dt>{item.address}</dt>
              </a>
              <dd
                itemProp="contactPoint"
                itemScope
                itemType="http://schema.org/ContactPoint"
              >
                <a
                  className={styles.addressLink}
                  href={`tel:${item.phone}`}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  <span itemProp="telephone" content={item.phone}>
                    {item.phone}
                  </span>
                </a>
              </dd>
            </dl>
          </div>
        </div>
        {item.testimonial && (
          <Testimonial testimonial={item.testimonial} desktop={true} />
        )}
      </article>
    </li>
  )
}

export default Office
